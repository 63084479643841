.centre-section1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.section1{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.month-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #800080;
    color: white;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 20px;
    width: 90%;
}

.month-div h1{
    font-size: 55.79px;
}

.month-div h2{
    font-size: 26.35px;
    font-weight: 300;
}

.pen3div{
    margin-top:20px;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    
}

.infodiv{
    width: 500px;
    height: 90px;
    border-radius: 10px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}

.info-div-flex{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items:flex-start;
}
.infodiv h4{
    font-weight: 300;
    font-size: 1rem;
}
.infodiv h5{
    font-size: 4rem;
    margin-top: 0px;
}

.pie {
    width: 35%;
}