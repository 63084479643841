.side-nav {
  padding: 0;
  padding-right: 40px;
  margin: 0;
  background-color: #fffafa;
}

.nav {
  margin-top: 1rem;
}

.nav ul {
  list-style: none;
  padding: 0px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.nav ul li {
  margin: 0.2rem 0;
}

.nav ul li a {
  text-decoration: none;
  color: #000000;
  padding: 0.8rem 2rem;
  display: block;
  text-align: center;
  border-radius: 0 1.5rem 1.5rem 0;
  font-size: 1rem;
}

.nav ul li a:hover {
  background-color: #dab9d8;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
    gap: 10px;
  margin: 1rem;
}

.user-pic img {
  width: 25px;
  border-radius: 50%;
}

.user-name p {
  margin: 10px 0;
  font-weight: bold;
  color: #000000;
}

.logout-btn {
  padding: 10px 50px 10px 50px;
  border: none;
  background-color: #800080;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  animation: shake3856 0.3s linear infinite both;
  background-color: #dab9d8;
  color: #800080;
}

@keyframes shake3856 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  20% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }

  40% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }

  60% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }

  80% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
