.itinerary-details {
  font-family: Arial, sans-serif;
}

.main-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
}

.package-container,
.enquiry-container {
  flex: 1;
  margin-right: 2rem;
  padding: 1rem;
  background-color: azure;
  border-radius: 1rem;
}

.package-name {
  font-size: 24px;
  font-weight: bold;
  padding: 1rem;
  margin-bottom: 10px;
  background-color: #F6EEF5;
  border-radius: 1rem;
}

.package-description {
  font-size: 16px;
  color: #555;
  padding: 1rem;
  background-color: #F6EEF5;
  border-radius: 1rem;
}

.enquiry-container div {
  margin-bottom: 10px;
}

.days-container {
  margin-top: 20px;
}

.day-container {
  background: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.day-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.event-container {
  margin-bottom: 10px;
}

.event {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.event-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detail {
  flex: 1;
  min-width: 120px;
  padding: 10px 0;
}

.label {
  display: block;
  font-weight: 500;
  color: #6c757d;
}

.data {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}

.inclusions-exclusions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.inclusions-exclusions div {
  flex: 1;
  margin-right: 20px;
}

.inclusions-exclusions li,
.tandcs-disclaimers li {
  margin-bottom: 5px;
}

.tandcs-disclaimers {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tandcs-disclaimers div {
  flex: 1;
  margin-right: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.actions button {
  margin-left: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.actions img {
  width: 20px;
  height: 20px;
}

.tabs-container {
  margin-top: 20px;
}

.tabs-header {
  display: flex;
  border-bottom: 2px solid #ccc;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: none;
  background-color: #f9f9f9;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s, color 0.3s;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tab.selected {
  background-color: #652361;
  color: white;
  border-color: #652361; 
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

ul, ol {
  list-style: none;
}