.enquiry-details {
  border-radius: 8px;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.enquiry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.created-date {
  font-size: 1.4rem;
  color: #333;
}

.status {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status span {
  margin-inline-start: 1rem;
  color: #fff;
  background-color: #8d008d;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}

.enquiry-content {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  padding: 1.5rem;
  border-radius: 12px;
  background-color: #fafafa;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 1.5rem;
}

.row div {
  flex: 1;
}

.row div div:first-child {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 6px;
}

.row div div:last-child {
  font-size: 1.1rem;
  color: #333;
  word-break: break-word;
}

.passengers {
  display: flex;
  justify-content: space-between;
}

.passengers div {
  flex: 1;
}

.passengers div div:first-child {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 6px;
}

.passengers div div:last-child {
  font-size: 1.2rem;
  color: #333;
}

.edit-status-btn {
  padding: 0.75rem 1.5rem;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: rgb(100, 220, 100);
}

.edit-status-btn:hover {
  background-color: rgba(100, 220, 100, 0.8);
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.actions button {
  margin: 0 0.5rem;
  padding: 0.5rem 1.2rem;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actions .edit-btn {
  background-color: #8d008d;
}

.actions .archive-btn {
  background-color: #ffc107;
}

.actions .delete-btn {
  background-color: #dc3545;
}

.actions .edit-btn:hover {
  background-color: rgba(0, 123, 255, 0.8);
}

.actions .archive-btn:hover {
  background-color: rgba(255, 193, 7, 0.8);
}

.actions .delete-btn:hover {
  background-color: rgba(220, 53, 69, 0.8);
}

button img {
  width: 20px;
  height: 20px;
}

.allocate-btn {
  border: none;
  background-color: inherit;
  margin-left: 10px;
}

.action-btn {
  border: none;
  color: rgb(2, 17, 35);
  text-decoration: none;
  background-color: rgba(71, 157, 255, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
  border: solid 2px rgba(71, 157, 255, 0.8);
  margin-left: 10px;
}

.view-btn {
  padding: 3px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #61adff;
  border-radius: 50%;
}

.view-btn img {
  height: 30px;
  width: 30px;
}

.itinerary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid rgb(79, 79, 79) 2px;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
}
