@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.CentreSection {
    padding: 3rem;
    margin: 0;
    background-color: #EEE9E9;
    border-radius: 2.5rem;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  
  #search {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    font-family: Poppins, sans-serif;
    background-color: #FFFAFA;
    box-sizing: border-box;
  }