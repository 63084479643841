
body {
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #fffafa;
  overflow-x: hidden;
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.Sidebar {
  background: #e9ecef;
  padding: 1rem;
  height: 100%;
}

.CentreSection {
  background: #ffffff;
  padding: 1rem;
  flex-grow: 1;
  overflow-x: scroll;
}

.RightSide {
  background: #e9ecef;
  padding: 1rem;
  height: 100%;
}