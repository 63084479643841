.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f7f7f7;
  }
  
  .unauthorized-icon {
    font-size: 5rem;
    color: #ff4b5c;
    margin-bottom: 1rem;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: 1.2rem;
    color: #666;
  }
  