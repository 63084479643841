.form {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h3 {
  margin-bottom: 20px;
}

.field-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
textarea,
select,
input[type="number"],
input[type="time"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: vertical;
}

.error {
  border-color: red;
}

.removeBtn {
  background-color: #f44;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.removeBtn:hover {
  background-color: #d33;
}

.item-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-field input {
  flex: 1;
  margin-right: 10px;
}

.day-panel,
.event-panel {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
}

.event-panel {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 4rem;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
}

.event-row {
  display: grid;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 5px;
}

.event-row label {
  font-weight: bold;
  margin-bottom: 5px;
}

.event-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.event-input span {
  flex: 1;
}

.event-input button {
  margin-left: 10px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.row label {
  flex: 0 0 150px;
  font-weight: bold;
}

.form-actions {
  text-align: right;
}

button[type="submit"],
button[type="button"] {
  background-color: #8d008d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover,
button[type="button"]:hover {
  background-color: #800080;
}

.error {
  color: red;
  margin-top: 10px;
}

.btn-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.btn-column * {
  width: 100%;
  margin: 0;
}

.btn-column * {
  width: 100%;
  margin: 0;
}

.event-icon {
  font-size: 40px;
}