.login-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  min-height: 100vh;
}

.login {
    min-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .login h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
  .login input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .login button[type="submit"]  {
    display: block;
    width: 75%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #800080;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .login button:disabled {
    background-color: #aaa;
  }

  .login button[type="submit"]:hover {
    background-color: rgba(128,0,128, 0.8);
  }
  
  .login .error {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ff4d4d;
    border-radius: 5px;
    background-color: #ffcccc;
    color: #cc0000;
    text-align: center;
  }
  
  .signup-link {
    text-align: center;
    margin-top: 20px;
    color: #333;
  }
  
  .signup-link a {
    color: #8d008d;
    text-decoration: none;
  }
  
  .signup-link a:hover {
    text-decoration: underline;
  }
  